<template>
  <div>
    <br/><br/>
    <v-row>
      <v-col cols="12">
        <br/>
        <p v-if="popup" @click="showModal('reparation-simple')">La couture simple permet de rapprocher les
          matières par
          un point main ou une pince
          <v-icon color="black darken-2" dark small>
            mdi-information
          </v-icon>
        </p>

      </v-col>
    </v-row>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <h6>DIMENSIONS ET QUANTITÉS</h6>
          <p>Indiquez le nombre de trous correspondant à chaque dimension à l'aide des + et - </p>
        </v-col>
      </v-row>
      <v-row v-if="labels">
        <v-col v-for="(label, index) in labels" :key="index" cols="12" sm="3">
          <table border="0" class="dimensions">
            <tr>
              <td>{{ label.label }}</td>
              <td>
                <Vminusplusfield
                  :index="index"
                  :max="10"
                  :min="0"
                  :price="label.price"
                  :value="detailQte[index]"
                  @input="e => getInfos(e, label.bonus)"
                />
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h6>EMPLACEMENT</h6>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="emplacement"
            label=""
            name="input-7-4"
            outlined
            placeholder="Indiquez l'emplacement"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <image-preview
            ref="image_preview"
            :is-bonus-eligible="totalBonus > 0"
            :value="photo"
            @image="image => photo = image"
          />
        </v-col>
        <price :amount="price" :bonus="totalBonus" :display-bottom-line="totalQty > 0"/>
      </v-row>
      <bonus-image-confirm
        v-if="totalBonus > 0 && !photo"
        @skip-bonus="totalBonus = 0"
        @add-picture="$refs.image_preview.$refs.file.click()"
      />
      <v-row v-else-if="this.flux.indexEditProduit===null">
        <v-col v-if="error" cols="12">
          <div class="text-center" style="color:#FF0000">
            Vous devez sélectionner le nombre de trous par dimensions
          </div>
        </v-col>
        <confirm-flow
          v-if="totalQty > 0"
          @finalize="noarticle"
          @new-repair="onSelectNewRepair"
          @new-article="noarticle(false)"
        />
      </v-row>
      <v-row v-else>
        <v-col v-if="error" cols="12">
          <div class="text-center" style="color:#FF0000">Vous devez sélectionner le nombre de trous par
            dimensions
          </div>
        </v-col>
        <v-col class="text-center" cols="12">
          <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
        </v-col>
      </v-row>
    </v-form>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import trouPrice from '../../assets/data/price/trou.json';
import Vminusplusfield from '../interface/PlusMinusField.vue'

import {mapMutations, mapState} from 'vuex'
import Price from "@/components/shared/Price.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      labels: [],
      tabPromotions: [],
      detailQte: [0, 0, 0, 0],
      detailPrice: [0, 0, 0, 0],
      detailBonus: [0, 0, 0, 0],
      totalQty: 0,
      promotion: null,
      price: 0,
      totalBonus: 0,
      emplacement: null,
      error: false,
      popup: true,
      photo: null
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  created() {
    if (this.flux.componentName != 'trou-accroc-dechirure' || this.flux.activeTab != 'reparation_simple') {
      return null;
    }
    this.labels = trouPrice[0][this.flux.level2].labels
    this.tabPromotions = trouPrice[0][this.flux.level2].promotions

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.detailQte = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['details'][0]
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
    }
  },
  updated() {

    if (this.flux.componentName != 'trou-accroc-dechirure' || this.flux.activeTab != 'reparation_simple') {
      return null;
    }

    if (this.flux.level2) {
      this.labels = trouPrice[0][this.flux.level2].labels
      this.tabPromotions = trouPrice[0][this.flux.level2].promotions
    }

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.detailQte = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['details'][0]
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setActiveTab', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    noarticle(showCart = true) {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.setConstat('cart')
          this.$emit('showmodal', 'cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          activeTab: 'reparation_simple',
          type: 'Réparation simple',
          componentName: 'trou-accroc-dechirure',
          emplacement: this.emplacement,
          isCursor: this.isCursor,
          qte: 1,
          price: this.price,
          bonus: this.photo ? this.totalBonus : 0,
          photo: this.photo,
          details: [
            this.detailQte,
            this.detailPrice
          ]
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)
        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)
        this.setActiveTab('reparation_simple')
        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }


        //let reparation = {}
        let qteTotal = 0
        let priceTotal = 0

        for (let i = 0; i < this.detailPrice.length; i++) {
          if (this.detailPrice[i] > 0) {
            qteTotal = qteTotal + this.detailQte[i]
            priceTotal = priceTotal + this.detailPrice[i]
          }
        }

        liste_reparations.push({
          id: this.flux.cptReparation,
          activeTab: 'reparation_simple',
          type: this.flux.level3,
          componentName: 'trou-accroc-dechirure',
          emplacement: this.emplacement,
          isCursor: this.isCursor,
          qte: 1,
          price: this.price,
          bonus: this.photo ? this.totalBonus : 0,
          photo: this.photo,
          details: [
            this.detailQte,
            this.detailPrice
          ],
          elements: [{labels: this.labels.map(label => label.label)}]
        })

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }
        this.addProduct(data)
        this.getTotalCart(this.products)
        this.setLevel3(null)
        this.setComponentName(null)
      }
    },
    getInfos(value, bonus) {
      this.totalQty = 0
      this.promotion = 0
      this.$set(this.detailQte, value[0], value[1])
      this.$set(this.detailPrice, value[0], Math.round((this.flux.isDouble ? (value[2] * 1.2) : value[2]) * 100) / 100)
      this.$set(this.detailBonus, value[0], ((this.flux.isDouble ? bonus?.double : bonus?.non_double) ?? 0) * value[1])
      for (let i = 0; i < this.detailQte.length; i++) {
        this.totalQty = this.totalQty + this.detailQte[i]
      }

      for (let j = 0; j < this.tabPromotions.length; j++) {
        if (this.totalQty > 1 && this.totalQty < this.tabPromotions[j].qtemax) {
          this.promotion = this.tabPromotions[j].remise
          break;
        }
      }
      this.getPrice()
      this.error = false
    },
    getPrice() {
      this.price = 0
      this.totalBonus = 0
      for (let i = 0; i < this.detailPrice.length; i++) {
        this.price = this.price + this.detailPrice[i]
        this.totalBonus += this.detailBonus[i]
      }

      this.price = Math.round(this.price * 100) / 100
      this.error = false
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    },
  },
  components: {
    ConfirmFlow,
    BonusImageConfirm,
    ImagePreview,
    Price,
    Vminusplusfield
  }
}
</script>
<style scoped>
table.dimensions td {
  min-width: 65px !important;
}
</style>
